module.exports = {
  messages: {
    ASK_XING_NEW: 'New',
    ASK_XING_TITLE: 'Ask XING',
    TOP_BAR_BACK_BUTTON_ARIA_LABEL: 'Back',
    TOP_BAR_BACK_BUTTON_ARIA_LABEL_CUSTOM: 'Back to {section}',
    TOP_BAR_ME_MENU_DIMMER_ARIA_LABEL: 'Close menu',
    TOP_BAR_PROFILE_FILLING_INDICATOR_TITLE: 'Profile',
  },
};
